/**
 * Provide showing back button when referrer is same origin,
 * but different path
 */
export default function provideBackButton(): void {
  if (
    document.referrer &&
    document.referrer.indexOf(location.origin) == 0 &&
    document.referrer !== location.href
  ) {
    const backButton = document.getElementById("back-button");
    if (!backButton) return;

    backButton.onclick = (): void => {
      history.back();
    };
    backButton.removeAttribute("hidden");
  }
}
