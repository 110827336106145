/**
 * Provide opening <details> on navigate via anchor link
 */
export default function provideOpenDetailsOnNavigateViaAnchor(): void {
  document.addEventListener(
    "click",
    (event) => {
      const href = (event.target as HTMLElement | null)?.getAttribute?.("href");
      if (href?.startsWith?.("#") && href?.length > 1) {
        const target = document.querySelector(href);
        if (target?.nodeName?.toLowerCase() === "details") {
          (target as HTMLDetailsElement).open = true;
        }
      }
    },
    { passive: true }
  );
}
