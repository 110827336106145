/**
 * Log some credits to the console
 */
export default function provideConsoleInfo(): void {
  console.log.apply(
    null,
    [
      `
%c   _____ _                 _   %c _   _
%c  |_   _| |__   __ _ _ __ | | _%c| | | |
%c    | | | '_ \\ / _\` | '_ \\| |/ %c/ | | |
%c    | | | | | | (_| | | | |   <%c| |_| |
%c    |_| |_| |_|\\__,_|_| |_|_|\\_\\%c\\___/

         Say ThankU and Do Good
%c ______________________________________

 We want to say ThankU and give credits
 to all the great Open Source Software
 we used for creating www.thanku.social:

 * %cElm%c - A delightful language for
   reliable webapps:
   https://elm-lang.org/

 * %cTailwind CSS%c - A Utility-First CSS
   Framework for Rapidly Building
   Custom Designs
   https://tailwindcss.com/

 * %cParcel%c - Blazing fast, zero confi-
   guration web application bundler
   https://parceljs.org/

 * %cHandlebars%c - Minimal templating on
   steroids
   https://handlebarsjs.com/

 * %cExpress%c - Node.js web application
   framework
   https://expressjs.com/

 * %cTypeScript%c - JavaScript that scales
   https://www.typescriptlang.org/

 * %cNode.js%c - a JavaScript runtime build
   on Chrome's V8 JavaScript engine
   https://nodejs.org/

 * %cNeovim%c - hyperextensible Vim-based
   text editor
   https://neovim.io/

 * %cFirefox%c - Protect your life online
   with privacy-first products
   https://www.mozilla.org/firefox/

 %cThe ThankU team

`,
    ].concat("color:#043c5e color:#5FC2C5 ".repeat(15).trim().split(" "))
  );
}
