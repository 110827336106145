import focusWithin from "focus-within";
import smoothscroll from "smoothscroll-polyfill";

import provideBackButton from "./provider/backButton";
import provideConsoleInfo from "./provider/consoleInfo";
import provideOpenDetailsOnNavigateViaAnchor from "./provider/openDetailsOnNavigateViaAnchor";
import {
  detectSystemLanguage,
  getDocumentLanguage,
  redirectToStoredLanguage,
  initLanguageRecommendation,
  initLanguageSelector,
  isLanguageSupported,
} from "./language";

/**
 * Initializes the current page
 */
function init(): void {
  const systemLanguage = detectSystemLanguage();
  const documentLanguage = getDocumentLanguage();

  // if user has actively chosen a language, redirect user if needed
  redirectToStoredLanguage(documentLanguage);

  // apply polyfills
  smoothscroll.polyfill();
  focusWithin(document);

  // provide additional functionality
  provideBackButton();

  initLanguageSelector();
  if (
    isLanguageSupported(systemLanguage) &&
    systemLanguage !== documentLanguage
  ) {
    initLanguageRecommendation(systemLanguage);
  }

  provideOpenDetailsOnNavigateViaAnchor();
  provideConsoleInfo();
}

init();
